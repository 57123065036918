/*
-- 01  TYPOGRAFY SYSTEM

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

-Font weight
Default: 400
Medium:500
Semi-bold:600
Bold:700


- Line heights
Default: 1
small:1.05
Medium: 1.2
Pargraph default:1.6

-Leter spacing
  -0.5px
  0.75px

- 02 COLORS
-Primary : #0ca678
-Shades: #0a8560;
-White: #e7f6f2;
-Tints:  #ceede4
-Greys
#555
#333
--- 03 SHADOW

--- 4 BORDER-RADIUS

Default : 9px
Medium:11px

--- 5 WHITESPACE
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128


*/
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
.material-symbols-outlined {
  /* font-variation-settings: "FILL" 0, "wght" 1200, "GRAD" 0, "opsz" 48; */
  /* font-size: larger; */
  font-family: "Rubik", sans-serif;
  color: #0a8560;
  padding: 0;
}
/* Recommended icon sizes */
span.size-20 {
  font-size: 2rem;
  font-variation-settings: "OPSZ" 20;
}
span.size-24 {
  font-size: 2.4rem;
  font-variation-settings: "OPSZ" 24;
}
span.size-32 {
  font-size: 3.2rem;
  font-variation-settings: "OPSZ" 32;
}
span.size-48 {
  font-size: 4.8rem;
  font-variation-settings: "OPSZ" 48;
}

/* Rules for using icons as black on a light background. */
.dark {
  background: black;
  color: rgba(255, 255, 255, 1);
  font-variation-settings: "GRAD" -25;
}
.dark-inactive {
  background: black;
  color: rgba(255, 255, 255, 0.3);
  font-variation-settings: "GRAD" -25;
}

html {
  /* Font size: 10px
  10px / 16px(default) = 0.625(62.5%)
  Percentage of user's browserbfont-size settings
  1rem = 10px */
  font-size: 62.5%;
  overflow-x: hidden;
  /*Does NOT work on Safari */
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Rubik", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
  /* Only works if there is nothing absolutely positioned in relation to body */
  overflow-x: hidden;
}
/* -------------------------------------------------------------------------- */
/*                              GENERAL REUSABLE COMPONENT                    */
/* -------------------------------------------------------------------------- */
.container {
  /* 1140px */
  max-width: 120rem;
  /* auto figure margin left and right to same 1200 -32-32 = 1140 */
  margin: 0 auto;
  padding: 0 3.2rem;
}
.grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 3.2rem;
  justify-content: center;
  /* margin-bottom: 9.6; */
  /* grid-auto-flow: column; */
}
/* .grid:last-child {
  margin-bottom: 0;
} */
.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}
.grid-2-cols {
  grid-template-columns: repeat(2, 1fr);
}
.grid-3-cols {
  grid-template-columns: repeat(3, 1fr);
}
.grid-4-cols {
  grid-template-columns: repeat(4, 1fr);
}
.grid-5-cols {
  grid-template-columns: repeat(5, 1fr);
}
.grid-center-v {
  align-items: center;
}
.grid-center-h {
  justify-content: center;
}
.heading-primary,
.heading-secondary {
  font-weight: 600;
  color: #333;
  font-style: italic;
  /* color: #087f5b; */
  letter-spacing: -0.5px;
}
.heading-primary {
  font-size: 3.8rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}
.heading-secondary {
  font-size: 3.2rem;
  line-height: 1.2;
  margin-bottom: 4.8rem;
}
.heading-tertiary {
  font-size: 3rem;
  //line-height: 1.2;
  margin-bottom: 2rem;
}
.subheading {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #0ca678;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}
.btn {
  font-size: 1.6rem;
  border-radius: 2rem;
}
.my-btn,
.my-btn:link,
.my-btn:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1.2rem 1.6rem;
  border-radius: 20px;
  background-color: #ceede4;
  /* Put transition on original "state" */
  transition: background-color 0.3s;

  /* Only necessary for .btn */
  border: none;
  cursor: pointer;
  font-family: inherit;
}
.my-btn:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}
.my-btn:enabled {
  border: 3px solid #e7f6f2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for elevation */
  transform: translateY(-2px); /* Slightly lifts the button */
  transition: all 0.2s ease-in-out; /* Smooth animation for the raised effect */
}
.my-btn:enabled:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* More shadow on hover */
  transform: translateY(-4px); /* Lift more on hover */
}
.btn--full:link,
.btn--full:visited {
  background-color: #0ca678;
  color: #e7f6f2;
}
.btn--full:hover,
.btn--full:active {
  background-color: #0a8560;
}
.btn--outline:link,
.btn--outline:visited {
  background-color: #e7f6f2;
  color: #555;
}
.btn--outline:hover,
.btn--outline:active {
  background-color: #ceede4;
  /* border: 3px solid #e7f6f2; */
  /*Trick: to border inside*/
  box-shadow: inset 0 0 0 3px #e7f6f2;
}
.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-bottom-md {
  margin-bottom: 2.4rem !important;
}

*:focus {
  outline: none;
  /* outline: 4px dotted #0ca678;
  outline-offset: box; */
  /* outside element not inside */
  box-shadow: 0 0 0 0.3rem rgba(12, 166, 120, 0.5);
}

.link:link,
.link:visited {
  display: inline-block;
  color: #0ca678;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  transition: all 0.3s;
}

.link:hover,
.link:active {
  color: #0a8560;
  border-bottom: 1px solid transparent;
}
.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.list-item {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.list-icon {
  width: 3rem;
  height: 3rem;
  color: #0a8560;
}

strong {
  font-weight: 500;
}
